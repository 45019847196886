import { FC } from 'react'

const Footer: FC = () => {
	return (
		<div className='mt-8 flex flex-col justify-center items-center md:flex-row'>
			<span className='text-sm mb-2 md:mb-0 md:mr-20'>Copyright © 2022 Rastlinjaki Impact</span>
			<a href='https://www.linkedin.com/in/nejcrogelsek/' target='_blank' rel='noreferrer' className='text-sm'>Izvedba: Nejc Rogelšek</a>
		</div>
	)
}

export default Footer
