import { motion } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import video from './assets/videos/rastlinjaki_video.mp4'
import Rastlinjak1 from './assets/images/rastlinjak1.jpg'
import Rastlinjak2 from './assets/images/rastlinjak2.jpg'
import Rastlinjak3 from './assets/images/rastlinjak3.jpg'
import Rastlinjak4 from './assets/images/rastlinjak4.jpg'
import Rastlinjak5 from './assets/images/rastlinjak5.jpg'
import Rastlinjak6 from './assets/images/rastlinjak6.jpg'
import Rastlinjak7 from './assets/images/rastlinjak7.jpg'
import Rastlinjak8 from './assets/images/rastlinjak8.jpg'
import Rastlinjak9 from './assets/images/rastlinjak9.jpg'
import Rastlinjak10 from './assets/images/rastlinjak10.jpg'
import Rastlinjak11 from './assets/images/rastlinjak11.jpg'
import Rastlinjak12 from './assets/images/rastlinjak12.jpg'
import Rastlinjak13 from './assets/images/rastlinjak13.jpg'
import GroundScrew from './assets/images/ground_screw.jpg'
import { Footer } from './components/shared'
import ReactPlayer from 'react-player'
import { BsVolumeMuteFill, BsVolumeUpFill } from 'react-icons/bs'
import { Link } from 'react-scroll'

const App: FC = () => {
	const [isMuted, setIsMuted] = useState(true);
	const handleVideo = () => {
		setIsMuted(!isMuted);
	};

	useEffect(() => {
		const lightbox = document.createElement('div')
		lightbox.id = 'lightbox'
		document.body.appendChild(lightbox)

		const images = document.querySelectorAll('img')
		images.forEach(image => {
			image.addEventListener('click', () => {
				lightbox.classList.add('active')
				const img = document.createElement('img')
				const btn = document.createElement('button')
				btn.innerHTML = 'X'
				btn.addEventListener('click', e => {
					if (e.target !== e.currentTarget) return
					lightbox.classList.remove('active')
				})
				img.src = image.src
				if (image.src.match('rastlinjak10') || image.src.match('rastlinjak11')) {
					img.style.transform = 'rotate(90deg)'
					img.style.maxHeight = '50vh'
				}
				while (lightbox.firstChild) {
					lightbox.removeChild(lightbox.firstChild)
				}
				lightbox.appendChild(img)
				lightbox.appendChild(btn)
			})
		})

		lightbox.addEventListener('click', e => {
			if (e.target !== e.currentTarget) return
			lightbox.classList.remove('active')
		})

		return () => {
			lightbox.removeEventListener('click', e => {
				if (e.target !== e.currentTarget) return
				lightbox.classList.remove('active')
			})
		}
	}, [])

	return (
		<div className='px-4 pt-8 pb-4 w-full bg-image bg-cover bg-fixed'>
			<div>
				<div className='max-w-screen-md mx-auto flex flex-col justify-center items-center'>
					<div className='3xl:mb-[180px]'>
						<motion.h1
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }}
							className='uppercase text-3xl text-center font-bold mb-8 3xl:mb-16 3xl:mt-8'>Tunelni rastlinjaki</motion.h1>
						<motion.img
							className='mb-12 3xl:mb-16 cursor-pointer w-[500px]'
							src={Rastlinjak1}
							alt='3D model rastlinjaka'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
						<motion.div className='flex flex-col justify-center items-center'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }}>
							<h2 className='mb-2 text-center'><span className='font-bold'>IMPACT</span> - Zunanjetrgovinsko posredništvo</h2>
							<p className='mb-2'><span className='font-medium'>GSM:</span> <a href="tel:0038641690773">00 386 41 690 773</a></p>
							<p className='mb-2'><span className='font-medium'>E-mail:</span> <a href="mailto:janko.gorinsek@gmail.com">janko.gorinsek@gmail.com</a></p>
							<p className='mb-2'><span className='font-medium'>Skype:</span> jankojanko99</p>
						</motion.div>
					</div>
					<motion.div
						className='mb-40 mt-40 md:pr-[100px] 3xl:mb-[260px] 3xl:mt-16'
						initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
						animate={{ transform: 'translateY(0px)', opacity: 1 }}
					>
						<h2 className='text-xl font-medium mb-2'>TUNELNI RASTLINJAK</h2>
						<p className='mb-2'>Dimenzije:</p>
						<p className='mb-2 text-lg font-medium'>8m x 40m x 4,4 m</p>
						<p className='mb-2 text-lg'>
							Višina vertikalnih stranskih sten; <span className='font-medium'>1,8 m</span>, višina do prečke <span className='font-medium'>2,3 m</span>.
						</p>
						<p className='mb-2 text-lg'>
							Vrata so drsna, širina <span className='font-medium'>2 X 1,5 m = 3 m</span>, višina vrat <span className='font-medium'>2,4 m</span>.
						</p>
						<p className='mb-2 text-lg'>
							Rastlinjaki imajo , vse potrebne certifikate, še posebno je to pomembno za folijo, ki spada v vrh kvalitete evropskih folij.
						</p>
						<p className='mb-2 text-lg'>
							Folija "Kritis" z več kot 10 letnimi referencami.{' '}
							<a className='font-medium' href="https://www.plastikakritis.com" target='_blank' rel='noreferrer'>https://www.plastikakritis.com</a>
						</p>
						<p className='mb-2 text-lg'>Rastlinjak odlikuje enostavna postavitev, s pomočjo zemeljskih vijakov, <Link smooth={true} offset={-15} to='zemeljskivijak' className='font-medium cursor-pointer'>(gl. sliko)</Link></p>
						<p className='mb-2 text-lg'>Namestitev folije s pomočjo vpenjalnih profilov, <Link smooth={true} offset={-15} to='vpenjalniprofili' className='font-medium cursor-pointer'>(gl. sliko)</Link></p>
						<p className='mb-2 text-lg'>Tovrstni način omogoča enostavnejšo postavitev in prestavitev rastlinjaka.</p>
						<p className='mb-2 text-lg'>* rastlinjak ima vse komponente, z dodatnimi ojačitvami strehe, za področje ekscesnih zimskih padavin,</p>
						<p className='mb-2 text-lg'>reduktorsko stransko navijanje (prezračevanje),</p>
						<p className='mb-2 text-lg'>čelna, polikarbonatna okna na obeh straneh zgoraj.</p>
						<p className='mt-8 mb-8 text-lg'>
							Vabimo vas, da si komponente ogledate v našem skladišču v Dravogradu, oziroma se dogovorimo za eventuelni ogled rastlinjaka na terenu.
						</p>
						<p className='mb-2 text-lg'>S Spoštovanjem!</p>
					</motion.div>
					<motion.img
						className='mb-8 cursor-pointer'
						src={Rastlinjak2}
						alt='rastlinjak2'
						initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
						animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					<motion.img
						className='mb-8 cursor-pointer'
						src={Rastlinjak3}
						alt='rastlinjak3'
						initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
						animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					<div className='flex flex-col md:flex-row justify-center items-center mb-8'>
						<motion.img
							className='w-full md:w-[50%] mb-8 md:mb-0 md:pr-2 cursor-pointer'
							src={Rastlinjak4}
							alt='rastlinjak4'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
						<motion.img
							className='w-full md:w-[50%] md:pl-2 cursor-pointer'
							src={Rastlinjak5}
							alt='rastlinjak5'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					</div>
					<div className='flex flex-col md:flex-row justify-center items-center mb-8'>
						<motion.img
							className='w-full md:w-[50%] mb-8 md:mb-0 md:pr-2 cursor-pointer'
							src={Rastlinjak6}
							alt='rastlinjak6'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
						<motion.img
							className='w-full md:w-[50%] md:pl-2 cursor-pointer'
							src={Rastlinjak7}
							alt='rastlinjak7'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					</div>
					<motion.img
						className='mb-8 cursor-pointer'
						src={Rastlinjak8}
						alt='rastlinjak8'
						initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
						animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					<motion.img
						className='mb-8 cursor-pointer'
						src={Rastlinjak9}
						alt='rastlinjak9'
						initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
						animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					<div className='flex flex-col md:flex-row justify-center items-center mt-[22%] mb-[33%] sm:mb-[27%] md:mb-[137px] md:mt-[105px] md:w-[974px]'>
						<motion.img
							id='vpenjalniprofili'
							className='w-full md:w-[50%] mb-[55%] sm:mb-[48%] md:mb-0 md:pt-2 !rotate-90 cursor-pointer'
							src={Rastlinjak10}
							alt='rastlinjak10'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
						<motion.img
							className='w-full md:w-[50%] md:pb-2 !rotate-90 cursor-pointer'
							src={Rastlinjak11}
							alt='rastlinjak11'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
					</div>
					<div className='flex flex-col md:flex-row justify-center items-center mb-8'>
						<motion.img
							className='w-full md:w-[50%] mb-8 md:mb-0 md:pr-2 cursor-pointer'
							src={Rastlinjak12}
							alt='rastlinjak12'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }} />
						<div className='relative w-full md:w-[50%] md:pl-2'>
							<motion.img
								className='cursor-pointer'
								src={Rastlinjak13}
								alt='rastlinjak13'
								initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
								animate={{ transform: 'translateY(0px)', opacity: 1 }} />
							<span className='absolute pointer-events-none -bottom-[1px] left-0 right-0 bg-white bg-opacity-70 h-12 text-center uppercase text-base md:text-lg !leading-[3rem] md:ml-2'>Elektromototor 24 V, 80W</span>
						</div>
					</div>
					<div className='flex flex-col md:flex-row justify-center items-center md:items-start mb-8'>
						<div className='relative w-full md:w-[50%] mb-8 md:mb-0 md:pr-2'>
							<motion.img
								id='zemeljskivijak'
								className='cursor-pointer md:h-[670px] object-cover'
								src={GroundScrew}
								alt='Ground screw'
								initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
								animate={{ transform: 'translateY(0px)', opacity: 1 }} />
							<span className='absolute pointer-events-none bottom-0 left-0 right-0 bg-white bg-opacity-70 h-12 text-center uppercase text-base md:text-lg !leading-[3rem] md:mr-2'>Ground screw, Length 100 cm</span>
						</div>
						<motion.div className='relative w-full md:w-[50%] md:pl-2'
							initial={{ transform: 'translateY(-40px)', opacity: 0, transitionDuration: '0.5s' }}
							animate={{ transform: 'translateY(0px)', opacity: 1 }}>
							<ReactPlayer
								url={video}
								loop={true}
								playing={true}
								height='100%'
								width='100%'
								muted={isMuted}
								className='video-player'
							/>
							<div className='absolute top-2 right-2 bg-white p-2 rounded cursor-pointer' onClick={handleVideo}>
								{isMuted ? <BsVolumeMuteFill className='scale-150' /> : <BsVolumeUpFill className='scale-150' />}
							</div>
						</motion.div>
					</div>
				</div>
			</div>
			<Footer />
		</div >
	)
}

export default App
